@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';


.modal {
  width: 430px !important;

  @include tablet() {
    width: 380px !important;
  }

}

.root {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  .headerTitle {
    font-weight: 700;
    white-space: break-spaces;
    font-size: 24px;
    line-height: 30px;
  }
}


.itemTitle {
  color: black;
}

.timeWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  gap: 60px;

  @include tablet() {
    gap: 30px;
  }
}

.itemWraper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .time {
    display: flex;
    gap: 5px;
    align-items: center;

    .timeSlash {
      margin-top: 16px;
    }
  }
}

.btnsWrapp {
  display: flex;
  gap: 5px;


  @include tablet() {
    flex-wrap: wrap;
  }
}

.radio {
  display: flex;
  padding: 20px 10px;
  width: 50px;
  aspect-ratio: 1/1;
  align-items: center;
  justify-items: center;
  background-color: var(--secondary-btn-color) !important;
  border-radius: 5px !important;
  border: none !important;
  transition: all 0.3s ease-in-out;

  &:not(button:disabled):hover {
    background-color: var(--primary-btn-color) !important;
    color: white;
    span {
      color: white;
    }
  }
}

.radioChecked {
  background-color: var(--primary-btn-color) !important;
  color: white;
  span {
    color: white;
  }
}

.radioGroup {
  display: flex;

  .radio {
    display: flex;
    align-items: center;
    justify-items: center;
    height: 40px;
    width: 80px;
    background-color: var(--secondary-btn-color) !important;
    border-radius: 5px !important;
    border: none !important;

    &:hover {
      background-color: var(--primary-btn-color);
      color: white;
      span {
        color: white;
      }
    }

    &:first-child {
      padding: 0 20px;
      border-radius: 5px 0 0 5px !important;
    }

    &:last-child {
      padding: 0 10px;
      border-radius: 0 5px 5px 0 !important;
    }
  }

  .radioChecked {
    background-color: var(--primary-btn-color) !important;
    color: white;
  }
}


.footer {
  display: flex;
  justify-content: space-between;
  margin: 40px 0 20px;
}
@import "app/styles/global/mixins";
@import "app/styles/global/variables";

@keyframes moveUp {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes moveDown {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 100%, 0);
  }
}

.hero {
  background: var(--bg-gradient);

  @include mobile {
    padding-bottom: 30px;
    background: #F3FAFF;
  }

  &--psy {
    display: flex;
    align-items: center;
    background-color: #FFFDE9;
    background-image: none;

    @include tablet {
      background-color: #FFFDE9;
      flex-direction: column;
      align-items: center;
    }

    @include mobile {
      padding-bottom: 6px;
    }
  }
}

.hero__lines {
  position: relative;
  height: 700px;
  max-width: 580px;
  display: flex;
  column-gap: 20px;
  justify-content: center;
  flex-grow: 1;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;

  @include mobile {
    display: none;
  }
}

.hero__line {
  display: grid;
  gap: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  animation-name: moveDown;
  animation-duration: 100s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  svg {
    path {
      stroke: var(--primary-btn-color);
    }
    rect {
      stroke: var(--primary-btn-color);
    }
  }

  @include mobile {
    animation: none;
    display: grid;
    gap: 20px;
    grid-auto-flow: column;
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
}

.hero__line-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include mobile {
    display: block;
  }
}

.hero__line-group:nth-child(1) {
  // transform: translateY(50%);

  @include mobile {
    transform: none;
  }
}

.hero__line-group:nth-child(2) {
  justify-content: flex-start;

  .hero__line {
    animation-name: moveUp;
  }

  @include tablet {
    display: none;
  }
}

.hero__line-group:nth-child(3) {
  .hero__line {
    animation-duration: 150s;
  }

  @include laptop {
    display: none;
  }
}

.hero__line-group:nth-child(4) {
  display: none;
}

.hero__line-wrap {
  @include mobile {
    &:nth-child(2) {
      display: none;
    }
  }
}

.hero__bubbles {
  height: 700px;
  width: 54%;
  background-image: url("../../../assets/images/backgrounds/hero-bubbles@1x.png");
  background-repeat: no-repeat;
  background-position: right center;
  // background-size: 100% 100%;

  @include tablet {
    width: 100%;
    height: 100%;
    max-width: 500px;
    min-height: 467px;
    background-size: contain;
  }

  @include mobile {
    max-width: 375px;
    min-height: 350px;
  }
}

.hero__container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    flex-direction: column;
    align-items: stretch;
  }
}

.hero__content {
  max-width: 522px;
  margin-right: 30px;

  p {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: 1.52;
  }

  @include mobile {
    margin-right: 0;
    max-width: 400px;

    p {
      font-size: 16px;
    }
  }

  &--psy {
    @include mobile {
      padding: 0 10px;
    }
  }
}

.hero__title {
  margin-top: 0;
  padding-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 20px;

  span {
    display: inline-block;

    &::before {
      @include pseudo;

      left: -18px;
      top: -6px;
      width: 264px;
      height: 88px;
      mask-image: url("../../../assets/pictures/stack/stack.svg#title-line-1");
      background-color: var(--primary-btn-color);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  @include mobile {
    span::before {
      display: none;
      // width: 165px;
      // height: 52px;
    }
  }

  &--psy {
    span {
      &::before {
        top: -6px;
        width: 236px;
      }
    }

    @include mobile {
      padding-top: 0;
    }
  }
}

.hero__btn-wrap {
  display: flex;

  @include mobile {
    display: block;
  }
}

.hero__btn {
  width: 290px;
  margin-bottom: 20px;

  &--psy {
    margin-right: 20px;

    @include mobile {
      margin-right: 0;
    }
  }

  @include mobile {
    width: 100%;
  }
}

.find_specialist {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
}

@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.root {
  display: flex;
  padding: 20px 10px;
  flex-direction: column;
  gap: 12px;
  .required_asterisk {
    color: red;
  }
}

.row {
  max-width: 100%;
  display: grid;
  grid-template-rows: 0.2fr 0.8fr;
  gap: 4px;

  @include tablet {
    max-width: 100%;
  }
}

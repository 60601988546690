@import 'app/styles/breakpoints';

.root {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 50px;
  margin-bottom: 100px;
}

.formWrapper {
  display: grid;
  grid-template-rows: auto auto;
  gap: 20px;
}

.error {
  color: red;
}

.formGrid {
  position: relative;
  display: grid;
  padding-left: 15px;
  justify-content: start;
  grid-template-rows: repeat(6, auto);
  gap: 20px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    padding-left: unset;
  }
}

.tooltipContent {
  max-width: max-content;
}

.rowText {
  color: var(--dark-color);
}

.columnBtn {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.row {
  display: grid;
  align-items: center;
  grid-template-columns: 126px 1fr 258px;
  gap: 25px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    gap: 10px;
  }
  
}

.row:last-child{
  align-items: start;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
.row:last-child{
  align-items: start;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.activeInput {
  border-color: var(--primary-btn-color) !important;
}

.customInputHeight {
  height: 38px;
}

.dateSelect {
  padding-top: 20px;
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 5px;
}

.gridSelect {
  display: grid;
  grid-template-columns: 183px 127px;
  gap: 20px;
}

.underText {
  width: 70%;
  border-bottom: 1px dashed var(--gray-color);
}

.codeBtn {
  color: var(--primary-btn-color);
}

.btnWrapper {
  background-color: #fffde9;
  border-radius: 60px;
  width: 100%;
  justify-content: start;
  padding: 15px;
  display: grid;
  align-items: center;
  grid-template-columns: 126px auto 1fr;
  gap: 25px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-columns: auto;
    justify-content: center;
    text-align: center;
    grid-template-rows: auto auto auto;
    gap: 10px;
  }
}

.completeOrderUz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fffde9;
  border-radius: 60px;
  padding: 30px;
  width: 100%;
  gap: 25px;
}

.btn {
  padding: 15px 57px;
  height: unset !important;
}

.costWrapper {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 14px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 10px;
  }
}

.btnClose {
  padding: 4px;
  border-radius: 0 6px 6px 0;
  border: 1px solid #d9d9d9;
}

.closeIcon {
  width: 24px;
  height: 24px;
  mask-image: url('../../../1_shared/assets/pictures/close.svg');
  background-color: var(--primary-btn-color);
}

@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.promoBanners {
  padding-top: 44px;
  padding-bottom: 53px;
  color: var(--footer-text-color);
  background: url('../../../assets/images/promoCampaigns/NewYear2024/Background.png') repeat-y top left;
  background-size: cover;

  @include tablet {
    background: url('../../../assets/images/promoCampaigns/NewYear2024/Background.png') repeat-y top left;
    background-size: cover;
  }

  @include mobile {
    background: url('../../../assets/images/promoCampaigns/NewYear2024/Background-mob.png') repeat-y top left;
    background-size: cover;
  }

  &--light {
    background-color: $color-white;

    .programs__title {
      color: $color-dot-dark;
    }

    .programs__item-title {
      color: $color-dot-dark;
    }
  }

  @include mobile {
    padding-top: 20px;
    padding-bottom: 35px;

    &--light {
      padding-bottom: 20px;
    }
  }

  img {
    width: 48px;
    height: 48px;
  }
}

.promoBanners__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  .line {
    height: unset;
    width: unset;
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
  }

  .laptop {
    @include laptop-min {
      display: unset;
    }

    @include tablet {
      display: none;
    }
  }

  .mobile {
    @include laptop-min {
      display: none;
    }

    @include tablet {
      display: unset;
    }
  }
}

.promoBanners__titleBlock {
  margin-bottom: 20px;
  @include tablet {
    max-width: 160px;
  }
}

.promoBanners__title {
  color: $color-dot-dark;
  margin-right: 50px;
  max-width: 320px;
  margin-bottom: -15px;

  @include tablet {
    margin-bottom: -15px;
  }
}

.promoBanners__descriptionBlock {
  font-weight: 400;
  font-size: 16px;
  max-width: 560px;
}

.promoBanners__slider {
  @include laptop {
    padding-left: 20px;
  }

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.promoBanners__item {
  user-select: none;
  width: 360px;
  min-height: 365px;
  border-radius: 20px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  color: $color-dot-dark;
  background: $color-white;

  @include tablet {
    width: 293px;
    min-height: 363px;
    gap: 10px;
  }
}

.promoBanners__itemBlock {
  max-width: 312px;
  min-height: 110px;
  border-bottom: 1px solid $color-dot-grey;
}

.promoBanners__itemTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  @include tablet {
    font-size: 16px;
    line-height: 1.43;
    max-width: 245px;
    min-height: 82px;
  }
}

.promoBanners__itemDescription {
  font-size: 24px;
  font-weight: 500;

  @include tablet {
    font-size: 16px;
    line-height: 1.43;
    max-width: 245px;
    min-height: 82px;
  }
}

.promoBanners__selectButton {
  display: flex;
  align-self: center;
  width: 312px;
  min-height: 49px;

  .promoBanners__buttonText {
    text-align: center;
    vertical-align: center;
    color: $color-white;
    font-size: 16px;
    font-weight: 700;
  }

  @include tablet {
    max-width: 245px;
  }
}

.promoBanners__input {
  min-height: 45px;
  font-size: 20px;

  @include tablet {
    font-size: 24px;
    min-height: 45px;
  }
}

.promoDisabled {
  @include tablet {
    min-height: 70px;
  }
}

.promoBanners__benefits {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 115px;
  max-width: 312px;
  list-style-image: url('../../../assets/images/promoCampaigns/NewYear2024/icons/presentIcon.svg');

  @include tablet {
    min-height: 139px;
  }
}

.promoBanners__title-benefit {
  font-size: 16px;

  @include tablet {
    font-size: 14px;
    line-height: 20px;
  }
}

.promoBanners__title-discount {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  font-style: italic;
  font-family: '21 Cent Italic', sans-serif;

  @include tablet {
    line-height: 20px;
    font-size: 16px;
  }
}

.promoBanners__item-badge {
  padding: 10px;
  border-radius: 15px;
  color: var(--gray-color);
  background: var(--light-chip);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.05em;

  display: inline;
  position: absolute;
  bottom: 58%;
  right: 5%;

  @include tablet {
    right: 6%;
    bottom: 56%;
  }
}

.promoBanners__unAuth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 35px;

  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  button {
    margin-bottom: 24px;
    width: 360px;
  }
}

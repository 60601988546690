.indicators {
  position: absolute;
  top: 24px;
  left: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.remoteVideo {
  height: 90dvh;
  width: 100%;
  background: var(--light-blue);
  position: relative;

  video {
    object-fit: cover;
  }
}

.localVideo {
  position: absolute;
  top: 24px;
  right: 24px;
  max-width: 20%;
  aspect-ratio: 3/2;
}

.controlPanel {
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controlPanelGroup {
  display: flex;
  gap: 10px;
  align-items: center;
}

.micro {
  width: 24px;
  height: 24px;
  stroke: none;
  fill: white;
}

.startSessionButton {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.problem {
  padding-top: 40px;
  padding-bottom: 40px;

  @include mobile {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.problem__title {
  max-width: 729px;
  margin: 10px auto 30px;
  text-align: center;

  span {
    display: inline-block;

    &::before {
      @include pseudo;

      left: -36px;
      top: 2px;
      width: 291px;
      height: 72px;
      mask-image: url("../../../assets/pictures/stack/stack.svg#title-line-2");
      background-color: var(--primary-btn-color);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  @include mobile {
    margin-bottom: 20px;

    span::before {
      left: -7px;
      top: 2px;
      width: 143px;
      height: 36px;
    }
  }
}

.problem__list {
  @include list;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;

  @include mobile {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

.problem__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 210px;
  min-height: 130px;
  margin: 10px;
  padding: 20px;
  font-family: $font-secondary;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.17em;
  text-transform: uppercase;
  text-align: center;
  color: $color-dot-dark;
  background: #f3faff;
  border-radius: 20px;

  svg {
    path {
      stroke: var(--primary-btn-color);
    }
    rect {
      stroke: var(--primary-btn-color);
    }
  }

  p {
    margin-top: 5px;
    margin-bottom: 0;
  }

  @include mobile {
    width: auto;
    min-height: 80px;
    margin: 0;
    padding: 11px 18px;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0.2em;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

@import 'app/styles/breakpoints';

.root {
  display: grid;
  gap: 5px;
  margin-top: 10px;
  @media screen and (min-width: map-get($breakpoints, lg)) {
    margin-bottom: 19px;
  }
}

.darkText {
  color: var(--white-color);
}

.specializationTypography {
  color: var(--gray-color);
}

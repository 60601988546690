.dateSelect {
  font-family: 'Inter', sans-serif;
  padding-top: 20px;
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 5px;
}

.underText {
  border-bottom: 1px dashed var(--gray-color);
}

.btn {
  background-color: white;
  border: none;
  display: flex;

  &:hover {
    cursor: pointer;
  }
}